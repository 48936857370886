<template>
   <div v-if="amount.no">
        <searchBar :searchInput="false"></searchBar>
        <div class="order bgf color_333">
            <div class="detail">
                <p class="fs_16 bold">
                    <span>应付金额：</span>
                    <span class="fs_36 price_color">￥{{ amount.pay_amount }}</span>
                </p>
                <div class="fs_16 mt_20 flex_start">
                    <div class="bold">批次编号：</div>
                    <div class="mt_3">
                        <p>{{ amount.no }}</p>
                        <div class="fs_14 color_999 mt_20">
                            <p>提交数据：{{ amount.total_num }}条</p>
                            <p>金额详细：综合费用 ￥{{ amount.synthesis_fee }}、邮费 ￥{{ amount.express_fee }}</p>
                            <p>创建时间：{{ amount.create_time }}</p>
                        </div>
                    </div>
                </div>
                <div class="fs_16 bold mt_20 bank">
                    <p>账户名称：{{ amount.company || '深圳市职路信息技术有限公司' }}</p>
                    <p class="mt_20">企业账户：{{ amount.bank_account }}</p>
                    <p class="mt_20">开户银行：{{ amount.bank_name }}</p>
                    <p class="mt_20">开户支行：{{ amount.branch_bank }}</p>
                </div>
                <p class="fs_16 price_color">温馨提示：转账时请备注您的公司名称，我们将在3-5个工作日完成完税申请！</p>

                <div class="flex_cen">
                    <el-button type="primary" class="back-btn" @click="$router.push('/payTaxes/list')">返回列表</el-button>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import { s_removeItem } from "@/common/cache"
export default {
	name: 'TaxesOrder',
	data() {
		return {
			id: '',
			amount: {}
		}
	},
	created() {
		this.id = this.$route.params.id
		this.init()
	},
	beforeRouteLeave (to, from, next) {
		if(to.name != 'TaxesCheck') {
			s_removeItem('tableData')
			s_removeItem('amount')
		}
		next()
	},
	methods: {
		init() {
			this.$axios({
				url: `/ent/v3/taxes/${this.id}`,
				method: 'GET',
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					res.data.synthesis_fee = Number(res.data.service_fee)*100 + Number(res.data.tax_fee)*100
					res.data.synthesis_fee = (res.data.synthesis_fee/100).toFixed(2)
					this.amount = res.data
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
.order {
    padding: 76px 0;
    min-height: 66vh;
    .detail {
        width: 560px;
        margin: 0 auto;
    }
    .bank {
        padding: 20px 0 50px;
        border-top: 1px solid #D7DEEC;
    }
    .back-btn {
        margin-top: 100px;
        width: 160px;
        height: 40px;
        font-size: 15px;
    }
}
</style>